// This file was autogenerated. Please do not change.
export interface IExecuteTxRequest {
    gasPrice?: string;
    uid: string;
}

export default class ExecuteTxRequest {
    readonly _gasPrice: number | undefined;

    /**
     * Example: 1
     */
    get gasPrice(): number | undefined {
        return this._gasPrice;
    }

    readonly _uid: string;

    /**
     * Example: 123e4567-e89b-12d3-a456-426614174000
     */
    get uid(): string {
        return this._uid;
    }

    constructor(props: IExecuteTxRequest) {
        if (typeof props.gasPrice === 'string') {
            this._gasPrice = Number(props.gasPrice);
        }
        this._uid = props.uid.trim();
    }

    serialize(): IExecuteTxRequest {
        const data: IExecuteTxRequest = {
            uid: this._uid,
        };
        if (typeof this._gasPrice !== 'undefined') {
            data.gasPrice = String(this._gasPrice);
        }
        return data;
    }

    toJSON(): IExecuteTxRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            gasPrice: true,
            uid: typeof this._uid === 'string' && !!this._uid.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
