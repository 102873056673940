
import Vue from 'vue';
import { required, maxValue, minValue } from 'vuelidate/dist/validators.min';

import {
    getAssetsAllowance,
    getLiquidityAllowance,
    getPoolPositions,
    MANAGE_LIQUIDITY_TYPES,
    SET_ACTIVE_A_WRAPPED_ASSET,
    SET_ACTIVE_B_WRAPPED_ASSET,
    SET_LIQUIDITY_ALLOWANCE,
    SET_MANAGE_LIQUIDITY_TYPE,
} from 'Store/v2/DefiLiquidityPools';
import Balance from 'Entities/privatePresenter/Balance';
import numberFormater from 'Mixins/numberFormater';
import DeFiApi from 'Apis/DeFi';
import ApproveRequest from 'Entities/dex/ApproveRequest';
import ApiError from 'Entities/ApiError';
import ExecuteTxRequest from 'Entities/dex/ExecuteTxRequest';
import { IDexTransaction } from 'Entities/privatePresenter/DexTransaction';
import { SET_DEX_TRANSACTION, CHECK_APPROVAL_STATUSES } from 'Store/v2/Defi';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import AddLiquidityRequest from 'Entities/dex/AddLiquidityRequest';
import LiquidityApproveRequest from 'Entities/dex/LiquidityApproveRequest';
import RemoveLiquidityRequest from 'Entities/dex/RemoveLiquidityRequest';
import PreparedTxResponse from 'Entities/dex/PreparedTxResponse';
import Button from 'Control/Button.vue';
import Icon from 'UI/Icon.vue';
import MoneyInput from 'Control/MoneyInput.vue';

interface Data {
    MANAGE_LIQUIDITY_TYPES: any;
    QUANTITY_MIN_VALUE: number;
    aAssetQuantity: number;
    bAssetQuantity: number;
    aAssetButtons: any;
    bAssetButtons: any;
    slippage: number;
    slippageButtons: any;
    networkFee: number;
    isFirstAssetApproving: boolean;
    isSecondAssetApproving: boolean;
    transactionUID: string;
    isFeeCalculated: boolean;
    removedPercent: number;
    removedPercentButtons: any;
    isLiquidityApproving: boolean;
    isApproveChecked: boolean;
    isLiquidityRemoving: boolean;
    removedSlippage: number;
    removedSlippageButtons: any;
    CHECK_APPROVAL_STATUSES: any;
    MINIMAL_NATIVE_ASSET_BALANCE: number;
    timer: string;
    timerId: null | ReturnType<typeof setInterval>;
    remainingTime: number;
    showErrorTooltip: boolean;
}

interface Methods {
    setManageLiquidityType: (data: MANAGE_LIQUIDITY_TYPES) => void;
    setFirstAssetValue: (data: number) => void;
    setSecondAssetValue: (data: number) => void;
    setSlippage: (data: number) => void;
    handleFirstInput: (data: any) => void;
    handleSecondInput: (data: any) => void;
    preApproveFirstAsset: () => void;
    preApproveSecondAsset: () => void;
    approveFirstAsset: () => void;
    approveSecondAsset: () => void;
    calculateFee: () => void;
    addLiquidity: () => void;
    setRemovedPercent: (data: number) => void;
    checkApproveLiquidity: () => void;
    approveLiquidity: () => void;
    confirmApproveLiquidity: () => void;
    removeLiquidity: () => void;
    setRemovedSlippage: (data: number) => void;
    confirmRemoveLiquidity: () => void;
    handleFirstAssetSelect: (data: any) => void;
    handleSecondAssetSelect: (data: any) => void;
    closeErrorTooltip: () => void;
}

interface Computed {
    manageLiquidityType: MANAGE_LIQUIDITY_TYPES;
    aAssetSymbol: string;
    bAssetSymbol: string;
    network: string;
    aAssetBalance: Balance | undefined;
    bAssetBalance: Balance | undefined;
    poolFee: number;
    aAssetPrice: number | undefined;
    aAssetReserve: number | undefined;
    bAssetPrice: number | undefined;
    bAssetReserve: number | undefined;
    activeAccountId: string;
    aAssetApprove: string;
    bAssetApprove: string;
    aAssetMaximumApproved: number;
    bAssetMaximumApproved: number;
    nativeBlockchainAssetBalance: Balance | undefined;
    aAssetPosition: number;
    bAssetPosition: number;
    poolShare: number;
    isLiquidityApproved: string;
    aAssetSymbolsArray: { symbol: string, isSelected: boolean }[] | undefined;
    bAssetSymbolsArray: { symbol: string, isSelected: boolean }[] | undefined;
    isFirstAssetWrapped: boolean;
    isSecondAssetWrapped: boolean;
    aAssetQuantityErrorText: string;
    bAssetQuantityErrorText: string;
    slippageErrorText: string;
    removedPercentErrorText: string;
    removedSlippageErrorText: string;
}

export default Vue.extend<Data, Methods, Computed>({
    mixins: [numberFormater],
    components: {
        Button,
        Icon,
        MoneyInput,
    },
    data() {
        return {
            MANAGE_LIQUIDITY_TYPES,
            CHECK_APPROVAL_STATUSES,
            QUANTITY_MIN_VALUE: 0.000000000000000001,
            MINIMAL_NATIVE_ASSET_BALANCE: 0.01,
            aAssetQuantity: 0,
            bAssetQuantity: 0,
            slippage: 0,
            removedSlippage: 0,
            networkFee: 0,
            isFirstAssetApproving: false,
            isSecondAssetApproving: false,
            transactionUID: '',
            isFeeCalculated: false,
            removedPercent: 0,
            isLiquidityApproving: false,
            isApproveChecked: false,
            isLiquidityRemoving: false,
            timer: '',
            remainingTime: 0,
            timerId: null,
            showErrorTooltip: true,
            aAssetButtons: [
                {
                    title: '10%',
                    callback: () => (this as any).setFirstAssetValue(0.1),
                },
                {
                    title: '25%',
                    callback: () => (this as any).setFirstAssetValue(0.25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setFirstAssetValue(0.5),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setFirstAssetValue(1),
                },
            ],
            bAssetButtons: [
                {
                    title: '10%',
                    callback: () => (this as any).setSecondAssetValue(0.1),
                },
                {
                    title: '25%',
                    callback: () => (this as any).setSecondAssetValue(0.25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setSecondAssetValue(0.5),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setSecondAssetValue(1),
                },
            ],
            slippageButtons: [
                {
                    title: '1%',
                    callback: () => (this as any).setSlippage(1),
                },
                {
                    title: '2%',
                    callback: () => (this as any).setSlippage(2),
                },
                {
                    title: '3%',
                    callback: () => (this as any).setSlippage(3),
                },
                {
                    title: '5%',
                    callback: () => (this as any).setSlippage(5),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setSlippage(49.99),
                },
            ],
            removedPercentButtons: [
                {
                    title: '25%',
                    callback: () => (this as any).setRemovedPercent(25),
                },
                {
                    title: '50%',
                    callback: () => (this as any).setRemovedPercent(50),
                },
                {
                    title: '75%',
                    callback: () => (this as any).setRemovedPercent(75),
                },
                {
                    title: '100%',
                    callback: () => (this as any).setRemovedPercent(100),
                },
            ],
            removedSlippageButtons: [
                {
                    title: '1%',
                    callback: () => (this as any).setRemovedSlippage(1),
                },
                {
                    title: '2%',
                    callback: () => (this as any).setRemovedSlippage(2),
                },
                {
                    title: '3%',
                    callback: () => (this as any).setRemovedSlippage(3),
                },
                {
                    title: '5%',
                    callback: () => (this as any).setRemovedSlippage(5),
                },
                {
                    title: 'Max',
                    callback: () => (this as any).setRemovedSlippage(49.99),
                },
            ],
        };
    },
    validations() {
        return {
            slippage: {
                required,
                maxValue: maxValue(49.99),
                minValue: minValue(0.1),
            },
            aAssetQuantity: {
                required,
                maxValue: maxValue(this.aAssetBalance ? this.aAssetBalance.free : 0),
                minValue: minValue(this.QUANTITY_MIN_VALUE),
            },
            bAssetQuantity: {
                required,
                maxValue: maxValue(this.bAssetBalance ? this.bAssetBalance.free : 0),
                minValue: minValue(this.QUANTITY_MIN_VALUE),
            },
            removedPercent: {
                required,
                minValue: minValue(1),
                maxValue: maxValue(100),
            },
            removedSlippage: {
                required,
                maxValue: maxValue(49.99),
                minValue: minValue(0.1),
            },
        };
    },
    computed: {
        aAssetQuantityErrorText() {
            if (!this.$v.aAssetQuantity.maxValue) {
                return `Value must be lower than ${this.aAssetBalance ? this.aAssetBalance.free.noExponents().getSeparatedDigits() : 0}`;
            }
            if (!this.$v.aAssetQuantity.minValue) {
                return `Value must be greater than ${this.QUANTITY_MIN_VALUE.noExponents()}`;
            }
            return '';
        },
        bAssetQuantityErrorText() {
            if (!this.$v.bAssetQuantity.maxValue) {
                return `Value must be lower than ${this.bAssetBalance ? this.bAssetBalance.free.noExponents().getSeparatedDigits() : 0}`;
            }
            if (!this.$v.bAssetQuantity.minValue) {
                return `Value must be greater than ${this.QUANTITY_MIN_VALUE.noExponents()}`;
            }
            return '';
        },
        slippageErrorText() {
            if (!this.$v.slippage.maxValue) {
                return 'Value must be lower than 49.99';
            }
            if (!this.$v.slippage.minValue) {
                return 'Value must be greater than 0.1';
            }
            return '';
        },
        removedPercentErrorText() {
            if (!this.$v.removedPercent.maxValue) {
                return 'Value must be lower than 100';
            }
            if (!this.$v.removedPercent.minValue) {
                return 'Value must be greater than 1';
            }
            return '';
        },
        removedSlippageErrorText() {
            if (!this.$v.removedSlippage.maxValue) {
                return 'Value must be lower than 49.99';
            }
            if (!this.$v.removedSlippage.minValue) {
                return 'Value must be greater than 0.1';
            }
            return '';
        },
        isFirstAssetWrapped() {
            return this.$store.state.DefiLiquidityPools.ui.isFirstAssetWrapped;
        },
        isSecondAssetWrapped() {
            return this.$store.state.DefiLiquidityPools.ui.isSecondAssetWrapped;
        },
        aAssetSymbolsArray() {
            return this.$store.state.DefiLiquidityPools.ui.aAssetSymbolsArray;
        },
        bAssetSymbolsArray() {
            return this.$store.state.DefiLiquidityPools.ui.bAssetSymbolsArray;
        },
        manageLiquidityType() {
            return this.$store.state.DefiLiquidityPools.ui.manageLiquidityType;
        },
        aAssetSymbol() {
            const { aAsset } = this.$store.state.DefiLiquidityPools.ui;
            return aAsset;
        },
        bAssetSymbol() {
            const { bAsset } = this.$store.state.DefiLiquidityPools.ui;
            return bAsset;
        },
        network() {
            return this.$store.state.DefiLiquidityPools.ui.network;
        },
        aAssetBalance() {
            const defiState = this.$store.state.Defi;
            if (!this.isFirstAssetWrapped) {
                const balance = defiState.fromBalances?.find((b) => b.assetSymbol === this.aAssetSymbol && b.blockchainName === this.network && b.accountId === this.activeAccountId);
                return balance ?? undefined;
            }
            const selectedAsset = this.aAssetSymbolsArray?.find((a) => a.isSelected);
            if (!selectedAsset) {
                return undefined;
            }
            const balance = defiState.fromBalances?.find((b) => b.assetSymbol === selectedAsset.symbol && b.blockchainName === this.network && b.accountId === this.activeAccountId);
            return balance ?? undefined;
        },
        bAssetBalance() {
            const defiState = this.$store.state.Defi;
            if (!this.isSecondAssetWrapped) {
                const balance = defiState.fromBalances?.find((b) => b.assetSymbol === this.bAssetSymbol && b.blockchainName === this.network && b.accountId === this.activeAccountId);
                return balance ?? undefined;
            }
            const selectedAsset = this.bAssetSymbolsArray?.find((a) => a.isSelected);
            if (!selectedAsset) {
                return undefined;
            }
            const balance = defiState.fromBalances?.find((b) => b.assetSymbol === selectedAsset.symbol && b.blockchainName === this.network && b.accountId === this.activeAccountId);
            return balance ?? undefined;
        },
        poolFee() {
            return this.$store.state.DefiLiquidityPools.ui.poolFee;
        },
        aAssetPrice() {
            return this.$store.state.DefiLiquidityPools.ui.poolInfo?.aAssetPrice;
        },
        aAssetReserve() {
            return this.$store.state.DefiLiquidityPools.ui.poolInfo?.aAssetReserve;
        },
        bAssetPrice() {
            return this.$store.state.DefiLiquidityPools.ui.poolInfo?.bAssetPrice;
        },
        bAssetReserve() {
            return this.$store.state.DefiLiquidityPools.ui.poolInfo?.bAssetReserve;
        },
        activeAccountId() {
            return this.$store.getters['Accounts/activeAccountID'];
        },
        aAssetApprove() {
            return this.$store.state.DefiLiquidityPools.ui.aAssetApproved;
        },
        bAssetApprove() {
            return this.$store.state.DefiLiquidityPools.ui.bAssetApproved;
        },
        aAssetMaximumApproved() {
            return this.$store.state.DefiLiquidityPools.ui.aAssetMaximumApproved;
        },
        bAssetMaximumApproved() {
            return this.$store.state.DefiLiquidityPools.ui.bAssetMaximumApproved;
        },
        nativeBlockchainAssetBalance() {
            const currentBlockchain = this.$store.state.Defi.blockchains?.find((b) => b.name === this.network);
            if (!currentBlockchain) {
                return undefined;
            }
            const { nativeAssetSymbol } = currentBlockchain;
            if (!nativeAssetSymbol) {
                return undefined;
            }
            const balance = this.$store.state.Balances.balances.find((b) => b.assetSymbol === nativeAssetSymbol && b.blockchainName === this.network && b.accountId === this.activeAccountId);
            return balance ?? undefined;
        },
        aAssetPosition() {
            return this.$store.state.DefiLiquidityPools.ui.aAssetPositionQuantity;
        },
        bAssetPosition() {
            return this.$store.state.DefiLiquidityPools.ui.bAssetPositionQuantity;
        },
        poolShare() {
            return this.$store.state.DefiLiquidityPools.ui.poolShare;
        },
        isLiquidityApproved() {
            return this.$store.state.DefiLiquidityPools.ui.isLiquidityApproved;
        },
    },
    methods: {
        closeErrorTooltip() {
            this.showErrorTooltip = false;
        },
        setManageLiquidityType(type) {
            if (this.poolShare === 0) {
                return;
            }
            // clean focus buttons
            if (this.$refs.RemovedPercent) {
                (this.$refs.RemovedPercent as any).cleanFocusButtons();
            }
            if (this.$refs.Slippage) {
                (this.$refs.Slippage as any).cleanFocusButtons();
            }
            if (this.$refs.RemoveSlippage) {
                (this.$refs.RemoveSlippage as any).cleanFocusButtons();
            }

            this.$store.commit(SET_MANAGE_LIQUIDITY_TYPE(type));
            this.networkFee = 0;
            this.transactionUID = '';
            this.isLiquidityApproving = false;
            this.isApproveChecked = false;
            this.isLiquidityRemoving = false;
            this.isFirstAssetApproving = false;
            this.isSecondAssetApproving = false;
            this.isFeeCalculated = false;
        },
        setFirstAssetValue(multiplier) {
            this.aAssetQuantity = (this.aAssetBalance!.free * multiplier).floor(18);
            this.bAssetQuantity = (this.aAssetQuantity * (this.aAssetPrice ?? 0)).floor(18);
            (this.$refs.secondAssetInput as any).cleanFocusButtons();
        },
        setSecondAssetValue(multiplier) {
            this.bAssetQuantity = (this.bAssetBalance!.free * multiplier).floor(18);
            this.aAssetQuantity = (this.bAssetQuantity * (this.bAssetPrice ?? 0)).floor(18);
            (this.$refs.firstAssetInput as any).cleanFocusButtons();
        },
        handleFirstInput(event) {
            this.aAssetQuantity = event;
            this.bAssetQuantity = event * (this.aAssetPrice ?? 0);
            (this.$refs.firstAssetInput as any).cleanFocusButtons();
            (this.$refs.secondAssetInput as any).cleanFocusButtons();
        },
        handleSecondInput(event) {
            this.bAssetQuantity = event;
            this.aAssetQuantity = event * (this.bAssetPrice ?? 0);
            (this.$refs.firstAssetInput as any).cleanFocusButtons();
            (this.$refs.secondAssetInput as any).cleanFocusButtons();
        },
        setSlippage(value) {
            this.slippage = value;
        },
        setRemovedSlippage(value) {
            this.removedSlippage = value;
        },
        setRemovedPercent(value) {
            this.removedPercent = value;
        },
        async preApproveFirstAsset() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: response } = await DeFiApi.privateDexLiquidityPrepareApprove(new ApproveRequest({
                    accountId: this.activeAccountId,
                    assetSymbol: this.aAssetSymbol,
                    blockchainName: this.network,
                    placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                    quantity: '1',
                }));
                if (response instanceof PreparedTxResponse) {
                    this.networkFee = response.networkFee;
                    this.transactionUID = response.uid;
                    this.isFirstAssetApproving = true;
                    this.isSecondAssetApproving = false;
                    return;
                }
                this.isSecondAssetApproving = false;
                this.isFirstAssetApproving = false;
                await this.$store.dispatch(getAssetsAllowance(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async preApproveSecondAsset() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: response } = await DeFiApi.privateDexLiquidityPrepareApprove(new ApproveRequest({
                    accountId: this.activeAccountId,
                    assetSymbol: this.bAssetSymbol,
                    blockchainName: this.network,
                    placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                    quantity: '1',
                }));
                if (response instanceof PreparedTxResponse) {
                    this.networkFee = response.networkFee;
                    this.transactionUID = response.uid;
                    this.isSecondAssetApproving = true;
                    this.isFirstAssetApproving = false;
                    return;
                }
                this.isSecondAssetApproving = false;
                this.isFirstAssetApproving = false;
                await this.$store.dispatch(getAssetsAllowance(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async approveFirstAsset() {
            try {
                if ((this.nativeBlockchainAssetBalance && this.nativeBlockchainAssetBalance.free < this.networkFee) || (!this.nativeBlockchainAssetBalance && this.networkFee !== 0)) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds');
                    return;
                }
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: response } = await DeFiApi.privateDexExecuteTransaction(new ExecuteTxRequest({
                    uid: this.transactionUID,
                }));
                this.isFirstAssetApproving = false;
                this.isSecondAssetApproving = false;
                if (response.id) {
                    const transaction: IDexTransaction = {
                        accountId: this.$store.getters['Accounts/activeAccountID'],
                        blockchainName: this.network,
                        createdAt: new Date().toISOString(),
                        placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                        aQuantity: '',
                        bQuantity: '',
                        status: response.status as any,
                        aAssetSymbol: this.aAssetSymbol,
                        bAssetSymbol: undefined as any,
                        id: response.id,
                        type: 'APPROVE',
                        updatedAt: '',

                    };
                    this.$store.commit(SET_DEX_TRANSACTION(transaction));
                }
                await this.$store.dispatch(getAssetsAllowance(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async approveSecondAsset() {
            try {
                if ((this.nativeBlockchainAssetBalance && this.nativeBlockchainAssetBalance.free < this.networkFee) || (!this.nativeBlockchainAssetBalance && this.networkFee !== 0)) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds');
                    return;
                }
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: response } = await DeFiApi.privateDexExecuteTransaction(new ExecuteTxRequest({
                    uid: this.transactionUID,
                }));
                this.isFirstAssetApproving = false;
                this.isSecondAssetApproving = false;
                if (response.id) {
                    const transaction: IDexTransaction = {
                        accountId: this.$store.getters['Accounts/activeAccountID'],
                        blockchainName: this.network,
                        createdAt: new Date().toISOString(),
                        placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                        aQuantity: '',
                        bQuantity: '',
                        status: response.status as any,
                        aAssetSymbol: this.bAssetSymbol,
                        bAssetSymbol: undefined as any,
                        id: response.id,
                        type: 'APPROVE',
                        updatedAt: '',

                    };
                    this.$store.commit(SET_DEX_TRANSACTION(transaction));
                }
                await this.$store.dispatch(getAssetsAllowance(undefined));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async calculateFee() {
            setTimeout(() => {
                this.showErrorTooltip = true;
            }, 100);
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                this.$v.slippage.$touch();
                this.$v.aAssetQuantity.$touch();
                this.$v.bAssetQuantity.$touch();
                if (!this.$v.slippage.$error && !this.$v.aAssetQuantity.$error && !this.$v.bAssetQuantity.$error) {
                    const { data: response } = await DeFiApi.privateDexLiquidityPrepareAddLiquidity(new AddLiquidityRequest({
                        aAssetQuantity: String(this.aAssetQuantity),
                        bAssetQuantity: String(this.bAssetQuantity),
                        aAssetSymbol: this.isFirstAssetWrapped ? this.aAssetSymbolsArray!.find((a) => a.isSelected)!.symbol : this.aAssetSymbol,
                        bAssetSymbol: this.isSecondAssetWrapped ? this.bAssetSymbolsArray!.find((a) => a.isSelected)!.symbol : this.bAssetSymbol,
                        accountId: this.activeAccountId,
                        blockchainName: this.network,
                        fee: String(this.poolFee),
                        slippage: String(this.slippage),
                        placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                    }));
                    this.networkFee = response.networkFee;
                    this.transactionUID = response.uid;
                    if (response && response.expiredAt) {
                        this.timer = response.expiredAt ?? '';
                        this.timerId = setInterval(() => {
                            this.remainingTime = Math.round((new Date(this.timer).valueOf() - Date.now()) / 1000);
                            if (this.remainingTime <= 0) {
                                this.remainingTime = 0;
                                this.timerId = null;
                                this.isFeeCalculated = false;
                            }
                        }, 1000);
                    }
                    this.isFeeCalculated = true;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during calculating fee, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async addLiquidity() {
            try {
                const nativeBlockchainSymbol = this.$store.state.Defi.blockchains?.find((b) => b.name === this.network)?.nativeAssetSymbol;
                if (!nativeBlockchainSymbol) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during getting native blockchain symbol');
                    return;
                }
                if (nativeBlockchainSymbol === (this.isFirstAssetWrapped ? this.aAssetSymbolsArray!.find((a) => a.isSelected)!.symbol : this.aAssetSymbol)) {
                    if (this.aAssetBalance && this.aAssetBalance.free < this.aAssetQuantity + this.networkFee + this.MINIMAL_NATIVE_ASSET_BALANCE) {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds or native asset balance will become less than 0.01');
                        return;
                    }
                } else if (nativeBlockchainSymbol === (this.isSecondAssetWrapped ? this.bAssetSymbolsArray!.find((a) => a.isSelected)!.symbol : this.bAssetSymbol)) {
                    if (this.bAssetBalance && this.bAssetBalance.free < this.bAssetQuantity + this.networkFee + this.MINIMAL_NATIVE_ASSET_BALANCE) {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds or native asset balance will become less than 0.01');
                        return;
                    }
                } else if ((this.nativeBlockchainAssetBalance && this.nativeBlockchainAssetBalance.free < this.networkFee + this.MINIMAL_NATIVE_ASSET_BALANCE) || (!this.nativeBlockchainAssetBalance)) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds or native asset balance will become less than 0.01');
                    return;
                }
                this.$store.commit(SET_LOADING_ON(undefined));
                await DeFiApi.privateDexExecuteTransaction(new ExecuteTxRequest({
                    uid: this.transactionUID,
                }));
                this.isFeeCalculated = false;
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Liquidity has been successfully added to the pool');
                await this.$router.push('/defi');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during calculating fee, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async checkApproveLiquidity() {
            setTimeout(() => {
                this.showErrorTooltip = true;
            }, 100);
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                this.$v.removedPercent.$touch();
                if (!this.$v.removedPercent.$error) {
                    await this.$store.dispatch(getLiquidityAllowance(this.removedPercent));
                    this.isApproveChecked = true;
                    this.isLiquidityApproving = false;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during checking approval, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async approveLiquidity() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: response } = await DeFiApi.privateDexLiquidityPrepareApproveLiquidity(new LiquidityApproveRequest({
                    accountId: this.activeAccountId,
                    aAssetSymbol: this.aAssetSymbol,
                    bAssetSymbol: this.bAssetSymbol,
                    blockchainName: this.network,
                    fee: String(this.poolFee),
                    percent: String(this.removedPercent),
                    placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                }));
                this.networkFee = response.networkFee;
                this.transactionUID = response.uid;
                this.isLiquidityApproving = true;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during approve, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async confirmApproveLiquidity() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                if ((this.nativeBlockchainAssetBalance && this.nativeBlockchainAssetBalance.free < this.networkFee) || (this.networkFee !== 0 && !this.nativeBlockchainAssetBalance)) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds');
                    return;
                }
                await DeFiApi.privateDexExecuteTransaction(new ExecuteTxRequest({
                    uid: this.transactionUID,
                }));
                this.isLiquidityApproving = false;
                await this.checkApproveLiquidity();
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during confirming approve, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async removeLiquidity() {
            setTimeout(() => {
                this.showErrorTooltip = true;
            }, 100);
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                this.$v.removedSlippage.$touch();
                this.$v.removedPercent.$touch();
                if (!this.$v.removedSlippage.$error && !this.$v.removedPercent.$error) {
                    const { data: response } = await DeFiApi.privateDexLiquidityPrepareRemoveLiquidity(new RemoveLiquidityRequest({
                        accountId: this.activeAccountId,
                        aAssetSymbol: this.isFirstAssetWrapped ? this.aAssetSymbolsArray!.find((a) => a.isSelected)!.symbol : this.aAssetSymbol,
                        bAssetSymbol: this.isSecondAssetWrapped ? this.bAssetSymbolsArray!.find((a) => a.isSelected)!.symbol : this.bAssetSymbol,
                        blockchainName: this.network,
                        fee: String(this.poolFee),
                        percent: String(this.removedPercent),
                        placementName: this.$store.state.DefiLiquidityPools.ui.placement,
                        slippage: String(this.removedSlippage),
                    }));
                    this.networkFee = response.networkFee;
                    this.transactionUID = response.uid;
                    this.isLiquidityRemoving = true;
                    this.timer = response.expiredAt ?? '';
                    this.timerId = setInterval(() => {
                        this.remainingTime = Math.round((new Date(this.timer).valueOf() - Date.now()) / 1000);
                        if (this.remainingTime <= 0) {
                            this.remainingTime = 0;
                            this.timerId = null;
                            this.isLiquidityRemoving = false;
                        }
                    }, 1000);
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during removing liquidity, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async confirmRemoveLiquidity() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                if ((this.nativeBlockchainAssetBalance && this.nativeBlockchainAssetBalance.free < this.networkFee) || (this.networkFee !== 0 && !this.nativeBlockchainAssetBalance)) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Not enough funds');
                    return;
                }
                await DeFiApi.privateDexExecuteTransaction(new ExecuteTxRequest({
                    uid: this.transactionUID,
                }));
                this.isLiquidityRemoving = false;
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Liquidity has been successfully removed from the pool');
                await this.$router.push('/defi');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during confirming removing liquidity, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        handleFirstAssetSelect(symbol) {
            this.$store.commit(SET_ACTIVE_A_WRAPPED_ASSET({ symbol }));
            this.handleFirstInput(0);
            this.$v.$reset();
        },
        handleSecondAssetSelect(symbol) {
            this.$store.commit(SET_ACTIVE_B_WRAPPED_ASSET({ symbol }));
            this.handleFirstInput(0);
            this.$v.$reset();
        },
    },
    async mounted() {
        document.addEventListener('click', this.closeErrorTooltip);

        // go to /defi if user refreshes the page during managing liquidity
        if (!this.aAssetSymbol || !this.bAssetSymbol) {
            await this.$router.push('/defi');
        }

        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            await this.$store.dispatch(getAssetsAllowance(undefined));
            if (this.$store.state.DefiLiquidityPools.hasOpenedPosition) {
                await this.$store.dispatch(getPoolPositions(undefined));
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during get assets allowance, please try again later');
            }
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeErrorTooltip);
        this.$store.commit(SET_LIQUIDITY_ALLOWANCE(CHECK_APPROVAL_STATUSES.NOT_APPROVED));
        this.isLiquidityApproving = false;
        this.isApproveChecked = false;
    },
    watch: {
        manageLiquidityType() {
            this.timerId = null;
            this.timer = '';
            this.remainingTime = 0;
        },
        slippage() {
            this.isFeeCalculated = false;
            this.$v.slippage.$reset();
        },
        aAssetQuantity() {
            this.isFeeCalculated = false;
            this.$v.aAssetQuantity.$reset();
        },
        bAssetQuantity() {
            this.isFeeCalculated = false;
            this.$v.bAssetQuantity.$reset();
        },
        removedPercent() {
            this.networkFee = 0;
            this.transactionUID = '';
            this.isLiquidityApproving = false;
            this.isApproveChecked = false;
            this.isLiquidityRemoving = false;
            this.$v.removedPercent.$reset();
        },
        removedSlippage() {
            this.networkFee = 0;
            this.transactionUID = '';
            this.isLiquidityApproving = false;
            this.isApproveChecked = false;
            this.isLiquidityRemoving = false;
            this.$v.removedSlippage.$reset();
        },
        async aAssetSymbolsArray() {
            await this.$store.dispatch(getAssetsAllowance(undefined));
            this.isFeeCalculated = false;

            this.networkFee = 0;
            this.transactionUID = '';
            this.isLiquidityApproving = false;
            this.isApproveChecked = false;
            this.isLiquidityRemoving = false;
        },
        async bAssetSymbolsArray() {
            await this.$store.dispatch(getAssetsAllowance(undefined));
            this.isFeeCalculated = false;

            this.networkFee = 0;
            this.transactionUID = '';
            this.isLiquidityApproving = false;
            this.isApproveChecked = false;
            this.isLiquidityRemoving = false;
        },
    },
});
